<template>
  <div
    class="about"
    style="padding: 32px; padding-bottom: 60px;">
    <h1>Loyalts</h1>

    <div class="card-wrapper">
      <div class="card-project">
        <div class="card-project__header card-project__header--between">
          <div class="card-project__header-info">
            <p>ID: 3647123</p>
            <p>|</p>
            <p>12 June 2020 15:34</p>
          </div>
          <div class="card-project__btn-wrapper">
            <button class="call-button call-button--danger call-button-size--medium is-plain">
              <font-awesome-icon
                :icon="dataLockIcon" />
              Block
            </button>
            <button class="call-button call-button--success call-button-size--medium is-plain">
              <font-awesome-icon
                :icon="dataReadingIcon" />
              Edit
            </button>
          </div>
        </div>
        <div class="card-project__body">
          <p class="card-project__title">
            Moscow
          </p>
          <p class="card-project__info">
            <font-awesome-icon
              :icon="dataAddressCard" />
            Oea Romana
            <call-badge
              type="primary">
              Admin
            </call-badge>
          </p>
        </div>
      </div>
      <div class="card-project__info-wrapper--vertical">
        <div class="card-project__info-block card-project card-project--center">
          <div class="warning icon">
            <font-awesome-icon
              :icon="dataUser" />
          </div>
          <p>24 Operators</p>
        </div>
        <div class="card-project__info-block card-project card-project--center">
          <div class="success icon ">
            <font-awesome-icon
              :icon="dataGift" />
          </div>
          <p>8 Projects</p>
        </div>
      </div>

      <div class="card-project__info-wrapper--horinzontal">
        <div class="card-project__info-block card-project">
          <div class="card-project__info-block__part">
            <div class="success icon">
              <font-awesome-icon
                :icon="dataGift" />
            </div>
            <div class="card-project__info-block__line">
              <p>Open Tasks</p>
              <p>38</p>
            </div>
            <div class="card-project__info-block__line success">
              <span>Free</span>
              <span>8</span>
            </div>
            <div class="card-project__info-block__line warning">
              <span>Assigned</span>
              <span>32</span>
            </div>
          </div>
          <div class="card-project__info-block__part">
            <div class="danger icon">
              <font-awesome-icon
                :icon="dataGift" />
            </div>
            <div class="card-project__info-block__line">
              <p>Open Tasks</p>
              <p>38</p>
            </div>
            <div class="card-project__info-block__line">
              <span>Free</span>
              <span>8</span>
            </div>
            <div class="card-project__info-block__line success">
              <span>Free</span>
              <span>8</span>
            </div>
            <div class="card-project__info-block__line warning">
              <span>Assigned</span>
              <span>32</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-wrapper">
      <div class="card-project">
        <div class="card-project__header card-project__header--between">
          <div class="card-project__header-info">
            <p>ID: 3647123</p>
            <p>|</p>
            <p>12 June 2020 15:34</p>
          </div>
          <div class="card-project__btn-wrapper">
            <button class="call-button call-button--danger call-button-size--medium is-plain">
              <font-awesome-icon
                :icon="dataLockIcon" />
              Block
            </button>
            <button class="call-button call-button--success call-button-size--medium is-plain">
              <font-awesome-icon
                :icon="dataReadingIcon" />
              Edit
            </button>
          </div>
        </div>
        <div class="card-project__body">
          <p class="card-project__title">
            Moscow
          </p>
          <p class="card-project__info">
            <font-awesome-icon
              :icon="dataAddressCard" />
            Oea Romana
            <call-badge
              type="primary">
              Admin
            </call-badge>
          </p>
        </div>
      </div>

      <div class="card-project__info-wrapper--vertical">
        <div class="card-project__info-block card-project">
          <div class="success icon">
            <font-awesome-icon
              :icon="dataGift" />
          </div>
          <div class="card-project__info-block__line">
            <p>Open Tasks</p>
            <p>38</p>
          </div>
          <div class="card-project__info-block__line success">
            <span>Free</span>
            <span>8</span>
          </div>
          <div class="card-project__info-block__line warning">
            <span>Assigned</span>
            <span>32</span>
          </div>
          <div class="card-project__info-block__footer">
            <div class="card-project__info-block__line warning">
              <span>Assigned</span>
              <span>32</span>
            </div>
          </div>
        </div>
      </div>

      <div class="card-project__info-wrapper--horinzontal">
        <div class="card-project__info-block card-project">
          <div class="card-project__info-block__part">
            <div class="success icon">
              <font-awesome-icon
                :icon="dataGift" />
            </div>
            <div class="card-project__info-block__line">
              <p>Open Tasks</p>
              <p>38</p>
            </div>
            <div class="card-project__info-block__footer">
              <div class="card-project__info-block__line">
                <span>Assigned</span>
                <span>32</span>
              </div>
            </div>
          </div>
          <div class="card-project__info-block__part">
            <div class="danger icon">
              <font-awesome-icon
                :icon="dataGift" />
            </div>
            <div class="card-project__info-block__line">
              <p>Open Tasks</p>
              <p>38</p>
            </div>
            <div class="card-project__info-block__line">
              <span>Free</span>
              <span>8</span>
            </div>
            <div class="card-project__info-block__line success">
              <span>Free</span>
              <span>8</span>
            </div>
            <div class="card-project__info-block__line warning">
              <span>Assigned</span>
              <span>32</span>
            </div>
          </div>
        </div>
      </div>

    </div>

    <h2>Selects</h2>
    <v-select
      :options="['asssa', 'sassaasssaaas']"
      :searchable="false"
      placeholder="All Teams"
      class="call-select" />
    <br>
    <div class="call-select-modal__wrapper call-input">
      <v-select
        :options="['asssa', 'sassaasssaaas']"
        :searchable="false"
        placeholder="All Teams"
        class="call-select-modal call-select" />
      <label>Team</label>
    </div>
    <h2>Inputs</h2>
    <call-input
      type-input="text"
      float-name-label="Name" />
    <br>
    <call-input
      type-input="search"
      placeholder="Search">
      <template slot="prefix">
        <font-awesome-icon
          :icon="dataIconSearch" />
      </template>
    </call-input>

    <!--
    call-button--success -- зеленый цвет кнопки
    call-button--danger -- красный цвет кнопки
    call-button--warning -- оранжевый цвет цвет кнопки
    call-button--info -- серый цвет кнопки

    class 'is-plain' -- делает цвет кнопки полупрозрачный
    class 'is-outlet' -- делает цвет кнопки полностью прозрачный
    class 'is-round' -- кнопка полностью круглая

    class 'call-button-size--medium' --обычный размер кнопки
    -->
    <h2>Switcher</h2>
    <div class="switcher-wrapper">
      <div class="switcher-item">
        <input
          id="radio-1"
          checked
          type="radio"
          name="radio"><label for="radio-1">Operator</label>
      </div>
      <div class="switcher-item">
        <input
          id="radio-2"
          type="radio"
          name="radio"><label for="radio-2">Team Leader</label>
      </div>
    </div>

    <h2>Buttons</h2>
    <div class="button-wrapper">
      <call-button
        :icon="dataAddIcon"
        type="success">
        Add user
      </call-button>

      <call-button
        :icon="dataAddIcon"
        type="danger">
        Cancel
      </call-button>

      <call-button
        :icon="dataAddIcon"
        type="warning">
        Re Asigned
      </call-button>

      <call-button
        :icon="dataAddIcon"
        type="info">
        Re Asigned
      </call-button>
      <call-button
        :icon="dataIconRefresh"
        type="success" />
      <br>
      <call-button
        type="success"
        :icon="dataViewIcon" />
      <br>
      <call-button
        type="warning"
        :icon="dataReadingIcon" />
      <br>
      <call-button
        type="danger"
        :icon="dataLockIcon" />
      <br>
      <call-button
        type="info"
        :icon="dataRowLeft" />
      <br>
      <call-button
        type="info"
        :icon="dataRowRight" />
    </div>

    <h2>Buttons Plain</h2>
    <div class="button-wrapper">
      <call-button
        :icon="dataAddIcon"
        type="success"
        plain>
        Add user
      </call-button>

      <call-button
        :icon="dataAddIcon"
        type="danger"
        plain>
        Cancel
      </call-button>

      <call-button
        :icon="dataAddIcon"
        type="warning"
        plain>
        Re Asigned
      </call-button>

      <call-button
        :icon="dataAddIcon"
        type="info"
        plain>
        Re Asigned
      </call-button>
    </div>

    <h2>Buttons Outlet</h2>
    <div class="button-wrapper">
      <call-button
        :icon="dataAddIcon"
        type="success"
        outlet>
        Add user
      </call-button>

      <call-button
        :icon="dataAddIcon"
        type="danger"
        outlet>
        Cancel
      </call-button>

      <call-button
        :icon="dataAddIcon"
        type="warning"
        outlet>
        Re Asigned
      </call-button>

      <call-button
        :icon="dataAddIcon"
        type="info"
        outlet>
        Re Asigned
      </call-button>
      <call-button
        :icon="dataIconRefresh"
        type="success"
        outlet />
      <br>
      <call-button
        type="success"
        outlet

        :icon="dataViewIcon" />
      <br>
      <call-button
        type="warning"
        outlet
        :icon="dataReadingIcon" />
      <br>
      <call-button
        type="danger"
        outlet
        :icon="dataLockIcon" />
      <br>
      <call-button
        type="info"
        :icon="dataRowLeft"
        outlet />
      <br>
      <call-button
        type="info"
        :icon="dataRowRight"
        outlet />
    </div>

    <h2>Buttons Round</h2>
    <div class="button-wrapper">
      <call-button
        outlet
        type="success"
        round
        not-border
        :icon="dataIconRefresh" />
      <br>
      <call-button
        type="success"
        outlet
        not-border
        round
        :icon="dataViewIcon" />
      <br>
      <call-button
        type="warning"
        outlet
        not-border
        round
        :icon="dataReadingIcon" />
      <br>
      <call-button
        type="danger"
        outlet
        not-border
        round
        :icon="dataLockIcon" />
      <br>
      <call-button
        type="info"
        round
        :icon="dataRowLeft" />
      <br>
      <call-button
        type="info"
        round
        :icon="dataRowRight" />
    </div>

    <h2>Buttons Disabled</h2>
    <div class="button-wrapper">
      <call-button
        :icon="dataAddIcon"
        type="success"
        outlet
        disabled>
        Add user
      </call-button>

      <call-button
        :icon="dataAddIcon"
        type="danger"
        outlet
        disabled>
        Cancel
      </call-button>

      <call-button
        :icon="dataAddIcon"
        type="warning"
        outlet
        disabled>
        Re Asigned
      </call-button>

      <call-button
        :icon="dataAddIcon"
        type="info"
        outlet
        disabled>
        Re Asigned
      </call-button>
      <call-button
        :icon="dataIconRefresh"
        type="success"
        outlet
        disabled />
      <br>
      <call-button
        type="success"
        outlet
        disabled
        :icon="dataViewIcon" />
      <br>
      <call-button
        type="warning"
        outlet
        disabled
        :icon="dataReadingIcon" />
      <br>
      <call-button
        type="danger"
        outlet
        disabled
        :icon="dataLockIcon" />
      <br>
      <call-button
        type="info"
        :icon="dataRowLeft"
        outlet
        disabled />
      <br>
      <call-button
        type="info"
        :icon="dataRowRight"
        outlet
        disabled />
    </div>
    <div class="button-wrapper">
      <call-button
        :icon="dataAddIcon"
        type="success"

        disabled>
        Add user
      </call-button>

      <call-button
        :icon="dataAddIcon"
        type="danger"

        disabled>
        Cancel
      </call-button>

      <call-button
        :icon="dataAddIcon"
        type="warning"

        disabled>
        Re Asigned
      </call-button>

      <call-button
        :icon="dataAddIcon"
        type="info"

        disabled>
        Re Asigned
      </call-button>
      <call-button
        :icon="dataIconRefresh"
        type="success"

        disabled />
      <br>
      <call-button
        type="success"

        disabled
        :icon="dataViewIcon" />
      <br>
      <call-button
        type="warning"

        disabled
        :icon="dataReadingIcon" />
      <br>
      <call-button
        type="danger"

        disabled
        :icon="dataLockIcon" />
      <br>
      <call-button
        type="info"
        :icon="dataRowLeft"

        disabled />
      <br>
      <call-button
        type="info"
        :icon="dataRowRight"

        disabled />
    </div>

    <!--
badge-success -- зеленый цвет кнопки
badge-danger -- красный цвет кнопки
badge-warning -- оранжевый цвет цвет кнопки
badge-info -- серый цвет кнопки
badge-primary -- синиц цвет кнопки

class 'is-plain' -- делает цвет кнопки полупрозрачный

class 'badge-size--medium' --обычный размер кнопки
class 'badge-size--big' --большой размер кнопки
-->

    <h2>Badge</h2>

    <div class="button-wrapper">
      <call-badge
        type="primary">
        Admin
      </call-badge>
      <br>
      <call-badge
        type="success">
        Team Leader
      </call-badge>
      <br>
      <call-badge
        plain
        size="big"
        type="success">
        <font-awesome-icon
          :icon="dataReadingIcon" />
        Assigned to You
      </call-badge>
      <br>
      <call-badge
        plain
        size="big"
        type="warning">
        <font-awesome-icon
          :icon="dataReadingIcon" />
        Assigned to You
      </call-badge>
      <br>
      <call-badge
        plain
        size="big"
        type="danger">
        <font-awesome-icon
          :icon="dataReadingIcon" />
        Assigned to You
      </call-badge>
    </div>

    <h2>Checked group</h2>
    <call-checked-group />
    <h2>Cards</h2>

    <div class="">
      <div class="card-project">
        <div class="card-project__header card-project__header--full">
          <button class="call-button call-button--warning call-button-size--medium is-plain">
            <font-awesome-icon
              :icon="dataUser" /> Re-Assign
          </button>
          <button class="call-button call-button--warning call-button-size--medium is-plain">
            <font-awesome-icon
              :icon="dataClock" />
            Re-Schedule
          </button>
          <button class="call-button call-button--success call-button-size--medium is-plain">
            <font-awesome-icon
              :icon="dataComment" />
            SMS
          </button>
          <button class="call-button call-button--success call-button-size--medium is-plain">
            <font-awesome-icon
              :icon="dataGift" />
            Gift
          </button>
          <button class="call-button call-button--success call-button-size--medium is-plain">
            <font-awesome-icon
              :icon="dataCheck" />
            Close
          </button>
        </div>
        <div class="card-project__body">
          <p class="card-project__title">
            ID: 2006251050069088
            <call-badge
              type="primary">
              Admin
            </call-badge>
          </p>
          <p class="card-project__info">
            <font-awesome-icon
              :icon="dataAddressCard" />
            Oea Romana
          </p>
          <p class="card-project__info">
            <font-awesome-icon
              :icon="dataGift" />
            12 June 1995
          </p>
        </div>

        <div class="card-project__footer project-card__footer--player">
          <p class="project-card__player-badge info-badge info-badge--warning">
            <font-awesome-icon
              :icon="dataClock" />22 June 16:30
          </p>
          <p class="project-card__player-badge info-badge info-badge--success">
            <font-awesome-icon
              :icon="dataUser" />Amelia Edwards
          </p>
        </div>
      </div>
    </div>

    <div class="buttons-wrapper">
      <div class="card-project">
        <div class="card-project__header card-project__header--between">
          <div class="card-project__header-info">
            <p>ID: 3647123</p>
            <p>|</p>
            <p>12 June 2020 15:34</p>
          </div>
          <div class="card-project__btn-wrapper">
            <button class="call-button call-button--danger call-button-size--medium is-plain">
              <font-awesome-icon
                :icon="dataLockIcon" />
              Block
            </button>
            <button class="call-button call-button--success call-button-size--medium is-plain">
              <font-awesome-icon
                :icon="dataReadingIcon" />
              Edit
            </button>
          </div>
        </div>
        <div class="card-project__body">
          <p class="card-project__title">
            Bairam Frootan
          </p>
          <p class="card-project__info">
            <font-awesome-icon
              :icon="dataAddressCard" />
            johndoe@affiliatecontrol.com
          </p>
          <p class="card-project__info">
            <font-awesome-icon
              :icon="dataGift" />
            Moscow
          </p>
        </div>

        <div class="card-project__footer project-card__footer--team">
          <div class="card-project__task-info">
            <div><span class="orange">248</span> <span>|</span><span class="green">344</span></div>
            <p>Tasks</p>
          </div>
          <div class="card-project__task-info">
            <div><span class="red">3</span></div>
            <p>Avg. per Day</p>
          </div>
          <div class="card-project__task-info">
            <div><span>3:32:53</span></div>
            <p>Spoken</p>
          </div>
        </div>
      </div>
    </div>

    <h2>Mobile things</h2>
    <div
      class="buttons-wrapper"
      style="display: flex; justify-content: space-evenly; flex-wrap: wrap">
      <div class="mobile-wrapper">
        <!-- шапка, с заголовком и кнопкой назад        -->
        <div class="mobile-header">
          <div class="mobile-header__back">
            <font-awesome-icon
              :icon="dataRowLeft" />
          </div>
          <h1 class="mobile-header__title">
            Players Without Deposits
          </h1>
          <call-input
            type-input="search"
            placeholder="Search">
            <template slot="prefix">
              <font-awesome-icon
                :icon="dataIconSearch" />
            </template>
          </call-input>
        </div>
        <!-- // шапка, с заголовком и кнопкой назад        -->

        <div class="mobile-body">
          <div class=" task__wrapper">
            <div class="task__group">
              <div class="task__group-title">
                Scheduled
              </div>
              <div class="task__item">
                <div class="task__title">
                  ID: 2006251050069024 <span class="task__comment-icon is-active"><font-awesome-icon
                    :icon="dataComment" /></span>
                </div>
                <p class="project-card__player-badge info-badge info-badge--warning">
                  <font-awesome-icon
                    :icon="dataClock" />22 June 16:30
                </p>
                <p class="project-card__player-badge info-badge info-badge--warning">
                  <font-awesome-icon
                    :icon="dataClock" />22 June 16:30
                </p>
              </div>

              <div class="task__item">
                <div class="task__title">
                  ID: 2006251050069024 <span class="task__comment-icon "><font-awesome-icon
                    :icon="dataComment" /></span>
                </div>
                <p class="info-badge info-badge--danger">
                  <font-awesome-icon
                    :icon="dataClock" />22 June 16:30
                </p>
                <p class=" info-badge info-badge--success">
                  <font-awesome-icon
                    :icon="dataUser" />Assigned to You
                </p>
              </div>
            </div>

            <div class="task__group">
              <div class="task__group-title">
                Assigned to You
              </div>
              <div class="task__item">
                <div class="task__title">
                  ID: 2006251050069095 <span class="task__comment-icon "><font-awesome-icon
                    :icon="dataComment" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--   нижние три кнопки, внизу экрана     -->
        <div class="mobile-menu">
          <nav class="nav--mobile">
            <a
              href=""
              class="nav__item nav__item--mobile"> <font-awesome-icon
                :icon="dataBriefcase" />Projects</a>
            <a
              href=""
              class="nav__item nav__item--mobile"><font-awesome-icon
                :icon="dataChartBar" />Reports</a>
            <a
              href=""
              class="nav__item nav__item--mobile"><font-awesome-icon
                :icon="dataCog" />Settings</a>
          </nav>
        </div>
        <!--   нижние три кнопки, внизу экрана     -->
      </div>

      <div class="mobile-wrapper">
        <!-- шапка, с заголовком и кнопкой назад        -->
        <div class="mobile-header">
          <h1 class="mobile-header__title">
            Projects
          </h1>
          <call-input
            type-input="search"
            placeholder="Search">
            <template slot="prefix">
              <font-awesome-icon
                :icon="dataIconSearch" />
            </template>
          </call-input>
        </div>
        <!-- // шапка, с заголовком и кнопкой назад        -->

        <div class="mobile-body">
          <v-select
            :options="['asssa', 'sassaasssaaas']"
            :searchable="false"
            placeholder="All Teams"
            class="call-select" />
          <div class=" task__wrapper">
            <div class="task__group">
              <div class="task__item">
                <div class="task__title no-margin">
                  Players Without Deposits <span class="task__comment-icon"> <span class="orange">23</span> | <span class="green">23</span>  </span>
                </div>
              </div>

              <div class="task__item">
                <div class="task__title no-margin">
                  VIP Players <span class="task__comment-icon is-active"><span class="task__comment-icon"> <span class="orange">76</span> | <span class="green">58</span>  </span>
                  </span>
                </div>
              </div>

              <div class="task__item">
                <div class="task__title no-margin">
                  New Users <span class="task__comment-icon"> <span class="orange">43</span> | <span class="green">23</span>  </span>
                </div>
              </div>

              <div class="task__item">
                <div class="task__title no-margin">
                  100$ Bonus <span class="task__comment-icon"> <span class="orange">16</span> | <span class="green">12</span>  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--   нижние три кнопки, внизу экрана     -->
        <div class="mobile-menu">
          <nav class="nav--mobile">
            <a
              href=""
              class="nav__item nav__item--mobile"> <font-awesome-icon
                :icon="dataBriefcase" />Projects</a>
            <a
              href=""
              class="nav__item nav__item--mobile"><font-awesome-icon
                :icon="dataChartBar" />Reports</a>
            <a
              href=""
              class="nav__item nav__item--mobile"><font-awesome-icon
                :icon="dataCog" />Settings</a>
          </nav>
        </div>
        <!--   нижние три кнопки, внизу экрана     -->
      </div>

      <div class="mobile-wrapper">
        <!-- шапка, с заголовком и кнопкой назад        -->
        <div class="mobile-header">
          <div class="mobile-header__back">
            <font-awesome-icon
              :icon="dataRowLeft" />
          </div>
          <h1 class="mobile-header__title">
            ID: 2006251050069095
          </h1>
        </div>
        <!-- // шапка, с заголовком и кнопкой назад        -->

        <div class="mobile-body">
          <div class="person-info__wrapper">
            <div class="person-info__data">
              <p>
                <font-awesome-icon
                  :icon="dataUser" />Name
              </p>
              <p>Oea Romana</p>
            </div>
            <div class="person-info__data">
              <p>
                <font-awesome-icon
                  :icon="dataGift" />Birthday
              </p>
              <p>12 June 1995</p>
            </div>
          </div>
          <div class="task__group">
            <div class="task__item">
              <div class="task__title">
                ID: 2006251050069024 <span class="task__comment-icon is-active"><font-awesome-icon
                  :icon="dataComment" /></span>
              </div>
              <p class="project-card__player-badge info-badge info-badge--warning">
                <font-awesome-icon
                  :icon="dataClock" />22 June 16:30
              </p>
              <p class="project-card__player-badge info-badge info-badge--warning">
                <font-awesome-icon
                  :icon="dataClock" />22 June 16:30
              </p>
            </div>
          </div>
          <div class="timeline__wrapper">
            <div>
              <div class="timeline__item">
                <span class="timeline__icon green">
                  <font-awesome-icon
                    :icon="dataClock" />
                </span>
                <div class="timeline__head">
                  <p class="timeline__date">
                    20 June 2020 14:56
                  </p>
                </div>
                <div class="timeline__body">
                  <p>Cancelled Call by Arina Belomestnykh</p>
                </div>
              </div>
            </div>

            <div>
              <div class="timeline__item">
                <span class="timeline__icon orange">
                  <font-awesome-icon
                    :icon="dataComment" />
                </span>
                <div class="timeline__head">
                  <p class="timeline__date">
                    20 June 2020 14:56
                  </p>
                  <p class="timeline__name">
                    <font-awesome-icon
                      :icon="dataUser" />
                    Amolika Shaikh
                  </p>
                </div>
                <div class="timeline__body">
                  <p>Player agrees to come back if we give him freebet</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--   нижние три кнопки, внизу экрана     -->
        <div class="mobile-menu">
          <nav class="nav--mobile">
            <a
              href=""
              class="nav__item nav__item--mobile"> <font-awesome-icon
                :icon="dataBriefcase" />Projects</a>
            <a
              href=""
              class="nav__item nav__item--mobile"><font-awesome-icon
                :icon="dataChartBar" />Reports</a>
            <a
              href=""
              class="nav__item nav__item--mobile"><font-awesome-icon
                :icon="dataCog" />Settings</a>
          </nav>
        </div>
        <!--   нижние три кнопки, внизу экрана     -->
      </div>

      <div class="mobile-wrapper">
        <!-- шапка, с заголовком и кнопкой назад        -->
        <div class="mobile-header">
          <div class="mobile-header__back">
            <font-awesome-icon
              :icon="dataRowLeft" />
          </div>
          <h1 class="mobile-header__title">
            ID: 2006251050069095
          </h1>
        </div>
        <!-- // шапка, с заголовком и кнопкой назад        -->

        <div class="mobile-body">
          <div class="person-info__wrapper">
            <div class="person-info__data">
              <p>
                <font-awesome-icon
                  :icon="dataUser" />Name
              </p>
              <p>Oea Romana</p>
            </div>
            <div class="person-info__data">
              <p>
                <font-awesome-icon
                  :icon="dataGift" />Birthday
              </p>
              <p>12 June 1995</p>
            </div>
          </div>
          <div class="task__group">
            <div class="task__item">
              <div class="task__title">
                ID: 2006251050069024 <span class="task__comment-icon is-active"><font-awesome-icon
                  :icon="dataComment" /></span>
              </div>
              <p class="project-card__player-badge info-badge info-badge--warning">
                <font-awesome-icon
                  :icon="dataClock" />22 June 16:30
              </p>
              <p class="project-card__player-badge info-badge info-badge--warning">
                <font-awesome-icon
                  :icon="dataClock" />22 June 16:30
              </p>
            </div>
          </div>
          <div class="timeline__wrapper">
            <div>
              <div class="timeline__item timeline__item--empty">
                <font-awesome-icon
                  :icon="dataUser" /> No Activity Yet
              </div>
              <button class="call-button call-button--success is-full is-plain">
                <font-awesome-icon
                  :icon="dataComment" /> Add Comment
              </button>
            </div>
          </div>
        </div>

        <!--   нижние три кнопки, внизу экрана     -->
        <div class="mobile-menu">
          <nav class="nav--mobile">
            <a
              href=""
              class="nav__item nav__item--mobile"> <font-awesome-icon
                :icon="dataBriefcase" />Projects</a>
            <a
              href=""
              class="nav__item nav__item--mobile"><font-awesome-icon
                :icon="dataChartBar" />Reports</a>
            <a
              href=""
              class="nav__item nav__item--mobile"><font-awesome-icon
                :icon="dataCog" />Settings</a>
          </nav>
        </div>
        <!--   нижние три кнопки, внизу экрана     -->
      </div>

      <div class="mobile-wrapper">
        <!-- шапка, с заголовком и кнопкой назад        -->
        <div class="mobile-header">
          <div class="mobile-header__back">
            <font-awesome-icon
              :icon="dataRowLeft" />
          </div>
          <h1 class="mobile-header__title">
            ID: 2006251050069095
          </h1>
        </div>
        <!-- // шапка, с заголовком и кнопкой назад        -->

        <div class="mobile-body">
          <div class="activity__wrapper">
            <div class="activity__block">
              <div class="activity-item">
                <div class="activity-item__head">
                  <span class="green">
                    <font-awesome-icon
                      :icon="dataComment" />
                  </span>
                  Finished Calls
                </div>
                <div class="activity-item__body">
                  128
                </div>
                <div class="activity-item__footer">
                  <span class="green">+24</span>
                  <p>|</p>
                  <span class="green">42%</span>
                </div>
              </div>

              <div class="activity-item">
                <div class="activity-item__head">
                  <span class="red">
                    <font-awesome-icon
                      :icon="dataClock" />
                  </span>
                  Cancelled
                </div>
                <div class="activity-item__body">
                  3h 32m 53s
                </div>
                <div class="activity-item__footer">
                  <span class="red">-2</span>
                  <p>|</p>
                  <span class="red">42%</span>
                </div>
              </div>
            </div>

            <div class="activity__block">
              <div class="activity-item activity-item--mobile">
                <div class="activity-item__head">
                  <span class="green">
                    <font-awesome-icon
                      :icon="dataGift" />
                  </span>
                  Gifts
                </div>
                <div class="activity-item--mobile-content">
                  <div class="activity-item__body">
                    64
                  </div>
                  <div class="activity-item__footer">
                    <span class="green">+24</span>
                    <p>|</p>
                    <span class="green">42%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--   нижние три кнопки, внизу экрана     -->
        <div class="mobile-menu">
          <nav class="nav--mobile">
            <a
              href=""
              class="nav__item nav__item--mobile"> <font-awesome-icon
                :icon="dataBriefcase" />Projects</a>
            <a
              href=""
              class="nav__item nav__item--mobile"><font-awesome-icon
                :icon="dataChartBar" />Reports</a>
            <a
              href=""
              class="nav__item nav__item--mobile"><font-awesome-icon
                :icon="dataCog" />Settings</a>
          </nav>
        </div>
        <!--   нижние три кнопки, внизу экрана     -->
      </div>
    </div>

    <div class="buttons-wrapper">
      <h2>Timeline</h2>
      <div class="timeline__wrapper">
        <div>
          <div class="timeline__item">
            <span class="timeline__icon green">
              <font-awesome-icon
                :icon="dataClock" />
            </span>
            <div class="timeline__head">
              <p class="timeline__date">
                20 June 2020 14:56
              </p>
            </div>
            <div class="timeline__body">
              <p>Cancelled Call by Arina Belomestnykh</p>
            </div>
          </div>
        </div>

        <div>
          <div class="timeline__item">
            <span class="timeline__icon red">
              <font-awesome-icon
                :icon="dataClock" />
            </span>
            <div class="timeline__head">
              <p class="timeline__date">
                20 June 2020 14:56
              </p>
            </div>
            <div class="timeline__body">
              <p>Cancelled Call by Arina Belomestnykh</p>
            </div>
          </div>
        </div>

        <div>
          <div class="timeline__item">
            <span class="timeline__icon orange">
              <font-awesome-icon
                :icon="dataComment" />
            </span>
            <div class="timeline__head">
              <p class="timeline__date">
                20 June 2020 14:56
              </p>
              <p class="timeline__name">
                <font-awesome-icon
                  :icon="dataUser" />
                Amolika Shaikh
              </p>
            </div>
            <div class="timeline__body">
              <p>Player agrees to come back if we give him freebet</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="buttons__wrapper">
      <h2>Activity</h2>
      <div class="activity__wrapper">
        <div class="activity__block">
          <div class="activity-item">
            <div class="activity-item__head">
              <span class="green">
                <font-awesome-icon
                  :icon="dataComment" />
              </span>
              Finished Calls
            </div>
            <div class="activity-item__body">
              128
            </div>
            <div class="activity-item__footer">
              <span class="green">+24</span>
              <p>|</p>
              <span class="green">42%</span>
            </div>
          </div>

          <div class="activity-item">
            <div class="activity-item__head">
              <span class="red">
                <font-awesome-icon
                  :icon="dataClock" />
              </span>
              Cancelled
            </div>
            <div class="activity-item__body">
              3h 32m 53s
            </div>
            <div class="activity-item__footer">
              <span class="red">-2</span>
              <p>|</p>
              <span class="red">42%</span>
            </div>
          </div>
        </div>

        <div class="activity__block">
          <div class="activity-item">
            <div class="activity-item__head">
              <span class="green">
                <font-awesome-icon
                  :icon="dataGift" />
              </span>
              Gifts
            </div>
            <div class="activity-item__body">
              64
            </div>
            <div class="activity-item__footer">
              <span class="green">+24</span>
              <p>|</p>
              <span class="green">42%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CallInput from '../components/common/CallInput';
import { faEye, faLock, faPen, faPlus, faSearch, faSyncAlt, faChevronLeft, faChevronRight, faUser, faClock, faComment, faGift, faCheck, faAddressCard, faLockOpen, faBriefcase, faChartBar, faCog } from '@fortawesome/free-solid-svg-icons';
import CallButton from '../components/common/CallButton';
import CallBadge from '../components/common/CallBadge';
import CallCheckedGroup from '../components/common/CallCheckedGroup';
export default {
  components: { CallCheckedGroup, CallBadge, CallButton, CallInput },
  data () {
    return {
      dataAddIcon: faPlus,
      dataIconSearch: faSearch,
      dataIconRefresh: faSyncAlt,
      dataViewIcon: faEye,
      dataReadingIcon: faPen,
      dataLockIcon: faLock,
      dataRowLeft: faChevronLeft,
      dataRowRight: faChevronRight,
      dataUser: faUser,
      dataClock: faClock,
      dataComment: faComment,
      dataGift: faGift,
      dataCheck: faCheck,
      dataAddressCard: faAddressCard,
      dataLock: faLockOpen,
      dataBriefcase: faBriefcase,
      dataChartBar: faChartBar,
      dataCog: faCog
    };
  }
};
</script>

<style>
  .button-wrapper {
    display: flex;
    margin-bottom: 16px;
  }
  .button-wrapper  button{
    margin-right: 8px;
  }
</style>
